import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import Email from '../components/Email';
import { formatReadingTime, getPath } from '../utils/helpers';

const StyledContainer = styled.div`
  padding: 25px;
  h2 {
    margin: 0;
  }
  .post-nav {
    display: flex;
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
    font-size: 0.8rem;
    li {
      justify-content: flex-end;
      flex: 1;
      text-align: right;
      padding: 0 10px;
      &:last-child {
        text-align: left;
        justify-content: flex-start;
      }
      &:only-child {
        justify-content: center;
        text-align: center;
      }
      a {
        color: currentColor;
        text-decoration: none;
        transition: color 0.2s;
        &:hover {
          color: #F36F5F;
        }
      }
    }
  }
`

class BlogPostTemplate extends React.Component {
  renderPost() {
    const post = this.props.data.markdownRemark;
    const title = get(post, 'frontmatter.title');
    const { previous, next } = this.props.pageContext;

    return (
      <StyledContainer key={post.fields.slug}>
        <h2 style={{ boxShadow: 'none', fontFamily: 'brother-1816' }}>
          {title}
        </h2>
        <hr />
        <small>
          {post.frontmatter.date}
          {` • ${formatReadingTime(post.timeToRead)}`}
        </small>
        <Email dangerouslySetInnerHTML={{ __html: post.html }} />
        {
          <ul className="post-nav">
            {previous && (
              <li>
                <Link to={getPath(previous.fields.slug)} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              </li>
            )}
            {next && (
              <li>
                <Link to={getPath(next.fields.slug)} rel="next">
                  {next.frontmatter.title} →
                </Link>
              </li>
            )}
          </ul>
        }
      </StyledContainer>
    )
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const post = this.props.data.markdownRemark;
    return (
      <Fragment>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.spoiler}
          slug={getPath(post.fields.slug)}
        />
        <Header />
        <Layout footer location={this.props.location} title={siteTitle}>
          { this.renderPost() }
        </Layout>
        <Footer />
      </Fragment>
    )
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
      }
      fields {
        slug
      }
    }
  }
`;